import React from 'react';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../../Context/DrawerContext';
import Navbar from '../LoginNavbar';
import DashView from './DashView';
import { BHPWrapper, ContentWrapper } from '../../common/src/base.style';

const EvaluadorComponent = props => (
  <BHPWrapper>
    <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
      <DrawerProvider>
        <Navbar />
      </DrawerProvider>
    </Sticky>
    <ContentWrapper>
      <DashView {...props} />
    </ContentWrapper>
  </BHPWrapper>
);

export default EvaluadorComponent;
