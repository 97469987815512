import React, { useState, useEffect, useRef } from 'react';
import Inputmask from 'inputmask';
import HolosLogo from '../img/logo_holos.png';
import BHPLogo from '../img/BHP_orange.png';
import {
  Form,
  FormInput,
  Row,
  Col,
  SheetInfoWrapper,
  Footer,
  ErrorMessage
} from './login.styles';
import { useUserContext } from '../../../../Context/user-context';

const LoginBox = props => {
  const { error, updateContext, BATTERY } = props;
  const [sheet, setSheet] = useState(props.sheet || 'data');
  const [editable, setEditable] = useState(false);
  const [rut, setRut] = useState('');
  const { logIn } = useUserContext();
  const inputEl = useRef(null);

  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    Inputmask({ mask: '99.999.999-9', numericInput: true }).mask(inputEl);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    setEditable(false);
    // console.log('submitting', rut);
    await logIn({ rut });
    // const cleanRut = rut.replace(/_/g, ''); // clean "_" chars from rut.
    // // console.log({ sheet, rut, cleanRut });
    // const CURRENT_CODE = `currentCode-${BATTERY}`;
    // const CURRENT_SHEET = `currentSheet-${BATTERY}`;
    // localStorage.setItem(CURRENT_CODE, cleanRut);
    // localStorage.setItem(CURRENT_SHEET, sheet);
    // await updateContext({
    //   rut: cleanRut,
    //   sheet
    // });
    // window.location.reload();
  };

  return (
    <Row>
      <Col>
        <div className='logo-wrapper'>
          <img src={HolosLogo} alt='holos logo' />
        </div>
        <br />
        <div className='start-info'>
          <p>
            Bienvenido a la plataforma de evaluación del{' '}
            <strong>Simulador Cambio Conductual de Conductores</strong>. Favor
            ingrese su RUT para comenzar.
          </p>
          <p>
            Esta información solo se utilizará para guardar su progreso en la
            encuesta y retomarla donde la dejó en caso que haya algún problema
            durante la captura de datos.
          </p>
          <p>
            En caso de necesitarlo, puede retomar sus avances volviendo a
            introducir su RUT en esta encuesta.
          </p>
          <p>
            Si tiene alguna pregunta o desea ponerse en contacto con nosotros,
            puede contactar a Claudia Paez al email{' '}
            <a href='mailto:cpaez@holos.cl'>cpaez@holos.cl</a>.
          </p>
        </div>
      </Col>
      <Col>
        <Form onSubmit={onSubmit}>
          <div className='logo-wrapper'>
            <img src={BHPLogo} alt='bhp logo' />
          </div>
          {/**
          // <FormInput>
          //   {editable ? (
          //     <>
          //       <label htmlFor="colegio">Colegio</label>
          //       <input name="colegio" value={sheet} onChange={e => setSheet(e.target.value)} />
          //     </>
          //   ) : (
          //     <>
          //       <label htmlFor="colegio">Colegio</label>
          //       <SheetInfoWrapper>
          //         <div>{sheet}</div>
          //         <button type="button" onClick={() => setEditable(!editable)}>
          //           No es correcto?
          //         </button>
          //       </SheetInfoWrapper>
          //     </>
          //   )}
          // </FormInput>
         */}

          <FormInput>
            <h3>Simulador Cambio Conductual de Conductores</h3>
          </FormInput>

          <FormInput>
            <label htmlFor='rutid'>Ingrese su Rut</label>
            <input
              ref={inputEl}
              name='rutid'
              value={rut}
              onChange={e => setRut(e.target.value)}
            />
            <p>
              <strong>Importante: </strong>
              Ingrese su RUT <u>sin puntos ni guión</u>. <br />
              En caso que termine en <em>K</em> reemplácelo por un <em>0</em>.
            </p>
          </FormInput>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Footer>
            <button type='submit'>Ingresar</button>
          </Footer>
        </Form>
      </Col>
      <div>
        <div className='bl' />
        <div className='tr' />
      </div>
    </Row>
  );
};
export default LoginBox;
