import axios from 'axios';

function removeDiacritics(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const logIn = async (rut, SHEET_ID, sheet = 'data', online) => {
  // console.log({ sheet });
  const normalized_string = removeDiacritics(sheet);
  // 'Universidad Adolfo Ibáñez' => 'Universidad Adolfo Ibanez'
  // console.log({ normalized_string });
  const cleaned_sheet = normalized_string
    .toLowerCase()
    .trim()
    .replace(/ /g, '-');
  // console.log({ cleaned_sheet });
  // 'Universidad Adolfo Ibanez' ==== universidad-adolfo-ibanez
  const body = {
    params: {
      rut,
      s_id: SHEET_ID,
      sheet: cleaned_sheet
    }
  };
  // if not online mode, return mock
  if (online === false) {
    console.log(body);
    return {
      rut,
      name: 'Testing',
      lastname: 'Holos',
      email: 'testing@testing.com'
    };
  }

  const { data } = await axios.get('/api/get-user', body);
  const { user } = data;
  // console.log('On Login');
  // console.log({ data });
  if (!user) {
    return { rut, registered: false };
  }
  return user;
};

export default logIn;
