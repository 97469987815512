import React from 'react';
// import { Link } from 'react-router-dom';
import ChatWrapper from './chat.style';
import BHPLogo from './img/BHP_orange.png';

class NabuIntro extends React.Component {
  render() {
    const { name, email } = this.props;
    return (
      <ChatWrapper>
        <div className='nabu-chat-person'>
          <img className='nabu-pic' src={BHPLogo} alt='nabu-pic' />
        </div>
        <div className='nabu-chat-wrapper'>
          <div className='finish_main'>
            <h3>Ha finalizado esta encuesta</h3>
            <p>
              Por favor espere para continuar.
              <br />
              <br />
              <a href='/'>
                <button className='primary-btn' type='button'>
                  Continuar
                </button>
              </a>
            </p>
          </div>
        </div>
      </ChatWrapper>
    );
  }
}

export default NabuIntro;
