const ratingValues = [
  {
    value: '1',
    text: 'Si'
  },
  {
    value: '2',
    text: 'No'
  },
  {
    value: '3',
    text: 'No aplica'
  },
];

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    A continuación se le presentara una serie de afirmaciones que debe responder en relación a la persona que está siendo evaluada, en base a las alternativas <em>Si</em>, <em>No</em> y <em>No aplica</em>.
                    <br>
                    Por favor, seleccione la opción que corresponda para cada caso.
                    <br><br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

const items = [
  { code: 'DPE_21', title: 'Intersecciones y virajes', statement: 'Entra a las intersecciones listo para frenar'  },
  { code: 'DPE_22', title: 'Intersecciones y virajes', statement: 'Chequea el tráfico en el cruce'  },
  { code: 'DPE_23', title: 'Intersecciones y virajes', statement: 'Deja pasar a otros'  },
  { code: 'DPE_24', title: 'Intersecciones y virajes', statement: 'Señaliza a tiempo el viraje'  },
  { code: 'DPE_25', title: 'Intersecciones y virajes', statement: 'Se ubica en la pista de viraje co anticipación'  },
  { code: 'DPE_26', title: 'Intersecciones y virajes', statement: 'Vira sólo cuando está despejado'  },
  { code: 'DPE_27', title: 'Intersecciones y virajes', statement: 'Bloquea el tráfico del lado de la curva de viraje'  },
  { code: 'DPE_28', title: 'Signos y señalética', statement: 'Arranca de manera pausada'  },
  { code: 'DPE_29', title: 'Signos y señalética', statement: 'Mira hacia adelante buscando signos y señalética'  },
  { code: 'DPE_30', title: 'Signos y señalética', statement: 'En un disco pare se detiene por completo'  },
  { code: 'DPE_31', title: 'Signos y señalética', statement: 'Se aproxima a las intersecciones con señalética preparado para frenar'  },
  { code: 'DPE_32', title: 'Signos y señalética', statement: 'Desacelera de forma paulatina'  },
  { code: 'DPE_33', title: 'Cruces', statement: 'Ajusta la velocidad a las condiciones para cruzar'  },
  { code: 'DPE_34', title: 'Cruces', statement: 'Si es necesario se detiene completamente'  },
  { code: 'DPE_35', title: 'Cruces', statement: 'Se detiene a una distancia adecuada de un cruce'  },
  { code: 'DPE_36', title: 'Cruces', statement: 'Evita pasar cambios al cruzar'  },
];

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: items.map(item => {
    const { code, title, statement } = item;
    return {
      name: code,
      title: title,
      elements: [
        {
          type: 'rating',
          name: code,
          title: statement,
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    };
  })
};

export default testInfo;
