const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: 'Siguiente',
  pagePrevText: 'Atrás',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMOG_PAGE_10',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'comment',
          name: 'FEV_00',
          title:
            'Indique en el espacio a continuación cómo se desempeñó en conductor frente a la situación de emergencia (por ej., piedrazo o reventón de cubierta). Por favor sea lo más detallado posible en su descripción, incluyendo qué cosas hizo bien y/o qué cosas hizo mal en respuesta a la situación de emergencia. Si por alguna razón no se simuló una situación de emergencia escribir “No se simuló situación de emergencia en esta oportunidad”.',
          isRequired: true
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_00',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'radiogroup',
          name: 'FEV_01',
          title:
            'En base a lo observado durante la simulación, ¿el conductor está recomendado para conducir camiones para esta compañía?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Recomendado'
            },
            {
              value: '2',
              text: 'Recomendado con observaciones'
            },
            {
              value: '3',
              text: 'No recomendado'
            }
          ]
        },
        {
          type: 'comment',
          title:
            'Indique qué tipo de entrenamiento adicional requiere este conductor',
          name: 'FEV_02',
          isRequired: true,
          visibleIf: '{FEV_01} <> "1"'
        }
      ]
    },

    {
      name: 'DEMOG_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'comment',
          name: 'FEV_03',
          title: 'Conclusiones y observaciones generales',
          isRequired: true
        }
      ]
    }
  ]
};

export default baseInfo;
