import React from 'react';
import Container from '../../../common/src/components/UI/ContainerTwo';
import BannerWrapper, { ContentWrapper } from './banner.style';
import Form from './Form';
import { useUserContext } from '../../../Context/user-context';

const LoginForm = props => {
  const { user, rut, error, isLoading, sheetId, sheetTitle } = useUserContext();
  // console.log('on evaluator dash');
  // console.log({ user, rut, error, isLoading, sheetId, sheetTitle });
  // console.log({ props });
  return (
    <BannerWrapper>
      <Container>
        <ContentWrapper>
          <div className='page-heading'>
            <h1>Simulador Cambio Conductual de Conductores</h1>
          </div>
          <div className='page-content'>
            <Form sheetId={sheetId} sheetTitle={sheetTitle} />
          </div>
        </ContentWrapper>
      </Container>
    </BannerWrapper>
  );
};

export default LoginForm;
