import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal, { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import LoginBox from '../LogInForm/LoginBox';

const StyledModal = Modal.styled`
  padding: 3rem 5rem;
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
  height: 100%;
`;

const ModalLogin = props => {
  const { active } = props;
  const [isOpen, setIsOpen] = useState(active);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 200);
    });
  }

  const showTestButton = false;

  return (
    <>
      {showTestButton && <button onClick={toggleModal}>Open modal</button>}
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        // onBackgroundClick={{}}
        // onEscapeKeydown={{}}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <LoginBox {...props} />
      </StyledModal>
    </>
  );
};

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
`;

const ModalSelector = props => {
  const [triggered, setTriggered] = useState(false);

  function afterOpen() {
    setTimeout(() => {
      setTriggered(true);
    }, 500);
  }
  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {
    afterOpen();
  }, []);

  const { active } = props;
  if (!triggered || !active) {
    return null;
  }
  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <ModalLogin active={props.active} {...props} />
    </ModalProvider>
  );
};

export default ModalSelector;
