import styled from 'styled-components';

// // Create the keyframes
// const hovering = keyframes`
//   0% {
//     transform: translateY(0px);
//   }

//   50% {
//     transform: translateY(-15px)
//   }

//   100% {
//     transform: translateY(0px);
//   }
// `;

const ChatWrapper = styled.div`
  ${'' /* height: 100vh; */}
  ${'' /* height: 100%; */}
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top: 15px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 564px) {
    padding-bottom: 125px;
  }
  .nabu-chat-person {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    .nabu-pic {
      width: 90%;
      ${'' /* animation: ${hovering} 5s ease infinite; */}
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
    }
    ${'' /* @media (max-width: 979px) {
      display: block;
      min-width: 0;
    } */}
    @media (max-width: 714px) {
      display: none;
    }
    ${'' /* @media (max-width: 564px) {
      display: none;
    } */}
  }
  .nabu-chat-wrapper {
    z-index: 0;
    width: 60%;
    padding: 0 3rem;
    .finish_main {
      ${'' /* display: flex; */}
      display: inherit;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 4px;
      position: relative;
      width: 100%;
      padding: 3.5rem 2rem;
      background-color: white;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
      p {
        color: #666;
        line-height: 1.8;
      }
    }

    @media (max-width: 714px) {
      width: 100%;
    }
  }
  .rsc-container {
    max-width: 840px;
    margin: 0 1rem;
    ${'' /* margin: 0 auto; */}
    border-radius: 8px;
    width: inherit;
    height: inherit;
    ${'' /* fix bug with react dnd component */}
    transform: none;
  }
  .rsc-header {
    padding: 1.5rem 2rem;
    height: 0px;
    .rsc-header-title {
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .rsc-content {
    padding: 0.5rem 1rem;
    ${'' /* height: 500px; */}
    height: 75vh;
    .rsc-ts-image-container {
      img {
        margin-right: 6px;
      }
    }
    .rsc-ts-bubble {
      font-weight: 500;
    }
  }
  .rsc-footer {
    .rsc-input {
      padding: 0.8rem 52px 0.8rem 1.5rem;
    }
    button {
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 16px;
    }
  }
  .primary-btn {
    border: none;
    color: white;
    background-color: ${({ theme }) => theme.colors.orange};
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }
`;

export default ChatWrapper;
