import React from 'react';
import { Redirect } from 'react-router-dom';
import Presentation from './Presentation';
import CarMovement from './CarMovement';
import CarBrakes from './CarBrakes';
import TrafficConduct from './TrafficConduct';
import TrafficConductCont from './TrafficConductCont';
import SimulationAct from './SimulationAct';

import FinalEv from './FinalEv';

import FinishedMessage from './FinishedMessage/NabuFinish';
import ErrorMessage from './ErrorMessage';
import postData from './API';
import getConfig from '../../config';
import VocationalWrapper from './vocacional.style';

import { useUserContext } from '../../Context/user-context';

class EvaluadorTest extends React.Component {
  state = {
    error: ''
  };

  componentDidMount = async () => {
    // const { BATTERY, updateContext } = this.props;
    const { BATTERY, updateContext, rut } = this.props;
    // const rut = localStorage.getItem(`currentCode-${BATTERY}`);

    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const caseId = this.props.state.user;
    // const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const { BATTERY, stage, rut = 'anon', sheet = 'data' } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    // await postData(data, user, rut);
    try {
      await postData(data, caseId, rut, sheet);
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
    // if (data.CONSENT === '2') {
    //   console.log('NO CONSENT');
    //   this.props.toNextStage(stageName);
    // }
    if (stage < 7) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, BATTERY, user, rut, sheet } = this.props;
    // console.log('on evaluador test');
    // console.log({ props: this.props, state: this.state });
    // const { BATTERY, user, rut, sheet } = this.props;
    // const stage = 5;
    const { error } = this.state;
    if (!!error) {
      return <ErrorMessage error={error} />;
    }

    if (stage > 6) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          name='Claudia Paez'
          email='cpaez@holos.cl'
        />
      );
    }
    if (stage === 6) {
      return (
        <FinalEv
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
          isLast
        />
      );
    }
    if (stage === 5) {
      return (
        <SimulationAct
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
        />
      );
    }
    if (stage === 4) {
      return (
        <TrafficConductCont
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
        />
      );
    }
    if (stage === 3) {
      return (
        <TrafficConduct
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
        />
      );
    }
    if (stage === 2) {
      return (
        <CarBrakes
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
        />
      );
    }
    if (stage === 1) {
      return (
        <CarMovement
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
        />
      );
    }
    return (
      <Presentation
        onComplete={this.onComplete}
        BATTERY={BATTERY}
        rut={rut}
        user={user}
        sheet={sheet}
        stage={stage}
      />
    );
  }
}

const WithWrapper = props => {
  // withAuth for padding-bottom for auth details footer
  const { user, isLoading } = useUserContext();
  // console.log('on evaluator test wrapper');
  // console.log({ user, rut, error, isLoading });
  // console.log({props})
  if (isLoading) {
    return <div>Cargando...</div>;
  }
  if (!user) {
    return <Redirect to='/' />;
  }
  return (
    <VocationalWrapper withAuth={props.withAuth}>
      <div className='tl' />
      <div className='tr' />
      <EvaluadorTest {...props} />
      <div className='bl' />
      <div className='br' />
    </VocationalWrapper>
  );
};

export default WithWrapper;
