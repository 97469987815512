import React from 'react';
import { Link } from 'react-router-dom';
import ChatWrapper from './chat.style';
import BHPLogo from './img/BHP_orange.png';

const NabuFinish = props => {
  const { name, email, BATTERY, rut } = props;

  const handleResetProgress = () => {
    // console.log('remove progress from');
    const USER_ID = `userId-${rut}-${BATTERY}`;
    const USER_STAGE = `userStage-${rut}-${BATTERY}`;
    // console.log({ USER_ID, USER_STAGE });
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_STAGE);
    window.location.reload();
  };

  return (
    <ChatWrapper>
      <div className='nabu-chat-person'>
        <img className='nabu-pic' src={BHPLogo} alt='nabu-pic' />
      </div>
      <div className='nabu-chat-wrapper'>
        <div className='finish_main'>
          <h3>Ha finalizado esta encuesta</h3>
          <p>
            La información entregada ha sido registrada de forma exitosa.
            <br />
            Puede volver al menu inicial para comenzar con otra evaluación.
            <br />
            <br />
            <Link to='/'>
              <button className='primary-btn' type='button'>
                Volver a Pantalla Evaluador
              </button>
            </Link>
          </p>
          <p>o</p>
          <button
            onClick={handleResetProgress}
            className='text-btn'
            type='button'
          >
            Responder Cuestionario Nuevamente
          </button>
          <div>
            <small>
              Iniciará una nueva encuesta desde el comienzo para participante
              RUT: <strong>{rut}</strong>
            </small>
          </div>
        </div>
      </div>
    </ChatWrapper>
  );
};

export default NabuFinish;
