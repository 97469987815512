import React from 'react';
import Presentation from './Presentation';

import Feedback from './Feedback';
// import AffectCircumplex from './AffectCircumplex';
// import NASATLX from './NASATLX';
import ReactionMeasure from './ReactionMeasure';
import SimulationSickness from './SimulationSickness';
import FinishedMessage from './FinishedMessage/NabuFinish';
import ErrorMessage from './ErrorMessage';
import postData from './API';
import getConfig from '../../config';
import VocationalWrapper from './vocacional.style';

class ParticipantPostTest extends React.Component {
  state = {
    error: ''
  };

  componentDidMount = async () => {
    // const { BATTERY, updateContext } = this.props;
    const { BATTERY, updateContext, rut } = this.props;
    // const rut = localStorage.getItem(`currentCode-${BATTERY}`);

    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE >= 3 ? USER_STAGE : 3,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE >= 3 ? USER_STAGE : 3,
        rut: CURRENT_CODE
      });
    }
  };

  handleFixStage = async () => {
    const { BATTERY, updateContext, rut } = this.props;
    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: 3,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: 3,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const caseId = this.props.state.user;
    // const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const { BATTERY, stage, rut = 'anon', sheet = 'data' } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    // await postData(data, user, rut);
    try {
      await postData(data, caseId, rut, sheet);
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
    // if (data.CONSENT === '2') {
    //   console.log('NO CONSENT');
    //   this.props.toNextStage(stageName);
    // }
    if (stage < 7) {
      return this.props.toNextStage(stageName);
    }
  };

  onNewCase = async data => {
    // const caseId = this.props.state.user;
    // const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const { BATTERY, stage, rut = 'anon', sheet = 'data' } = this.props;
    // console.log({ rut });
    // const stageName = `userStage-${rut}-${BATTERY}`;
    const { USER_ID: newUserId } = await getConfig(BATTERY, rut, true);
    try {
      await postData({ STAGE: 0, finished: 0 }, newUserId, rut, sheet);
      window.location.replace('/');
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
  };


  render() {
    const { stage, BATTERY, user, rut, sheet } = this.props;
    // const { BATTERY, user, rut, sheet } = this.props;
    // const stage = 5;
    const { error } = this.state;
    if (!!error) {
      return <ErrorMessage error={error} />;
    }

    if (stage > 6) {
      return (
        <FinishedMessage
          onComplete={this.onNewCase}
          BATTERY={BATTERY}
          rut={rut}
          name='Claudia Paez'
          email='cpaez@holos.cl'
        />
      );
    }
    if (stage === 6) {
      return (
        <Feedback
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
          isLast
        />
      );
    }
    // if (stage === 6) {
    //   return (
    //     <AffectCircumplex
    //       onComplete={this.onComplete}
    //       BATTERY={BATTERY}
    //       rut={rut}
    //       user={user}
    //       stage={stage}
    //     />
    //   );
    // }
    if (stage === 5) {
      return (
        <ReactionMeasure
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
        />
      );
    }
    if (stage === 4) {
      return (
        <SimulationSickness
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
        />
      );
    }
    // if (stage === 3) {
    //   return (
    //     <NASATLX
    //       onComplete={this.onComplete}
    //       BATTERY={BATTERY}
    //       rut={rut}
    //       user={user}
    //       stage={stage}
    //     />
    //   );
    // }
    if (stage === 3) {
      return (
        <Presentation
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          sheet={sheet}
          stage={stage}
        />
      );
    }
    return (
      <div>
        Ha ocurrido un error. por favor haga click en el siguiente botón para
        recargar.{' '}
        <button type='button' onClick={this.handleFixStage}>
          Recargar
        </button>
      </div>
    );
  }
}

const WithWrapper = props => {
  // withAuth for padding-bottom for auth details footer
  return (
    <VocationalWrapper withAuth={props.withAuth}>
      <div className='tl' />
      <div className='tr' />
      <ParticipantPostTest {...props} />
      <div className='bl' />
      <div className='br' />
    </VocationalWrapper>
  );
};

export default WithWrapper;
