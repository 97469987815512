const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: 'Siguiente',
  pagePrevText: 'Atrás',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMOG_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <span style="font-size:16px;">
                Previo a comenzar:
              </span>
              <br>
            </div>
          </legend>
          `
        },
        {
          type: 'radiogroup',
          name: 'SECOND_TEST',
          title: '¿Es la primera vez que participa en esta actividad?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Primera vez que participo'
            },
            {
              value: '2',
              text: 'Ya he participado anteriormente'
            }
          ]
        }
      ]
    },
  ]
};

export default baseInfo;
