const ratingValues = [
  {
    value: '0',
    text: '0'
  },
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    Nadie es perfecto. Incluso los mejores conductores cometen errores o incurren en infracciones en algún momento. Muchos de estos errores son triviales, pero otros son potencialmente más peligrosos. En esta sección nos interesa saber cuál es su percepción sobre su estilo de conducción.
                    <br><br>
                    El cuestionario es muy simple. Usted verá una serie de errores e infracciones. Frente a cada uno indique <em>cuán a menudo le ha pasado esto a usted</em> durante los últimos <strong>12 meses</strong>.
                    <br>
                    Para hacer esto seleccione la opción correspondiente (0 = <em>Nunca</em>; 1 = <em>Casi nunca</em>; 2 = <em>Ocasionalmente</em>; 3 = <em>Bastante a menudo</em>; 4 = <em>Frecuentemente</em>; 5 = <em>Casi todo el tiempo</em>).
                    <br><br>
                    Por supuesto, es imposible dar una respuesta absolutamente precisa. Nuestro interés es conocer sus impresiones generales, así que no gaste demasiado tiempo pensando en cada ítem y trate de ser lo más honesto posible.
                    <br><br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

const items = [
  { code: 'DBQ_01', statement: 'Ir sobre el límite de velocidad en carretera' },
  { code: 'DBQ_02', statement: 'Ir sobre el límite de velocidad en una zona residencial' },
  { code: 'DBQ_03', statement: 'Conducir demasiado cerca de un vehículo enfrente que haría difícil detenerse en caso de emergencia' },
  { code: 'DBQ_04', statement: 'Cruzar una intersección sabiendo que la luz ya está cambiando' },
  { code: 'DBQ_05', statement: 'Adelantar por la derecha' },
  { code: 'DBQ_06', statement: 'Conducir con la duda de si está o no sobre el límite de velocidad permitido' },
  { code: 'DBQ_07', statement: 'Tocar la bocina para demostrar su molestia hacia otros conductores' },
  { code: 'DBQ_08', statement: 'Enojarse con otro conductor y mostrarse hostil' },
  { code: 'DBQ_09', statement: 'Salir rápido de un semáforo para ganarle a otro conductor' },
  { code: 'DBQ_10', statement: 'Asomar el camión en una salida lo suficiente como para forzar a otros vehículos a ceder el paso' },
  { code: 'DBQ_11', statement: 'Enojarse con otro conductor y perseguirlo' },
  { code: 'DBQ_12', statement: 'Mantenerse hasta el último segundo en una pista que se va cerrando y entonces cambiarse súbitamente' },
  { code: 'DBQ_13', statement: 'Ir por la pista equivocada al salir de una rotonda o al doblar en una intersección' },
  { code: 'DBQ_14', statement: 'No recordar bien la ruta que acaba de hacer' },
  { code: 'DBQ_15', statement: 'Apretar o prender algo cuando quería hacer otra cosa' },
  { code: 'DBQ_16', statement: 'Salir de una luz en una marcha equivocada' },
  { code: 'DBQ_17', statement: 'Al retroceder, pasar a llevar algo que no había visto' },
  { code: 'DBQ_18', statement: 'Leer mal una indicación y tomar una salida equivocada' },
  { code: 'DBQ_19', statement: '“Despertarse” y darse cuenta que está en una ruta distinta de la planificada' },
  { code: 'DBQ_20', statement: 'Olvidarse donde dejó estacionado el camión' },
  { code: 'DBQ_21', statement: 'Al adelantar, subestimar la velocidad de un vehículo que viene por la pista contraria' },
  { code: 'DBQ_22', statement: 'Por ir buscando un espacio para cambiarse de pista, estar cerca de chocar un vehículo que está adelante suyo (choque por alcance)' },
  { code: 'DBQ_23', statement: 'Olvidar mirar por el espejo retrovisor antes de hacer una maniobra' },
  { code: 'DBQ_24', statement: 'Patinar por frenar muy fuerte en una ruta resbalosa o al doblar' },
  { code: 'DBQ_25', statement: 'Al doblar, casi pasar a llevar un ciclista que viene por dentro' },
  { code: 'DBQ_26', statement: 'Al hacer un viraje darse cuenta que hay peatones cruzando' },
  { code: 'DBQ_27', statement: 'Intentar adelantar un vehículo que está señalando un viraje a la izquierda' },
  { code: 'DBQ_28', statement: 'Pasarse un ceda el paso y apenas evitar chocar otro vehículo' },
];


const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_HEADER',
  html: `
  <legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
        0 = <em>Nunca</em>; 1 = <em>Casi nunca</em>; 2 = <em>Ocasionalmente</em>; 3 = <em>Bastante a menudo</em>; 4 = <em>Frecuentemente</em>; 5 = <em>Casi todo el tiempo</em>.
        </span>
      </span>
    </div>
    <br>
  </div>
</legend>
  `
};

const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: items.map(item => {
    const { code, statement } = item;
    return {
      name: code,
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: code,
          title: statement,
          isRequired: true,
          minRateDescription: 'Nunca',
          maxRateDescription: 'Casi todo el tiempo',
          rateValues: ratingValues
        }
      ]
    };
  })
};

export default baseInfo;
