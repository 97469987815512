const ratingValues = [
  {
    value: '1',
    text: 'Si'
  },
  {
    value: '2',
    text: 'No'
  },
  {
    value: '3',
    text: 'No aplica'
  },
];

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    A continuación se le presentara una serie de afirmaciones que debe responder en relación a la persona que está siendo evaluada, en base a las alternativas <em>Si</em>, <em>No</em> y <em>No aplica</em>.
                    <br>
                    Por favor, seleccione la opción que corresponda para cada caso.
                    <br><br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

const items = [
  { code: 'DPE_56', title: 'ACTITUDES DURANTE LA SIMULACIÓN', statement: 'Fue capaz de dominar sus emociones'  },
  { code: 'DPE_57', title: 'ACTITUDES DURANTE LA SIMULACIÓN', statement: 'Prestó atención a las instrucciones del instructor'  },
  { code: 'DPE_58', title: 'ACTITUDES DURANTE LA SIMULACIÓN', statement: 'Siguió las recomendaciones del instructor'  },
  { code: 'DPE_59', title: 'ACTITUDES DURANTE LA SIMULACIÓN', statement: 'Hizo preguntas que indican interés y curiosidad'  },
  { code: 'DPE_60', title: 'ACTITUDES DURANTE LA SIMULACIÓN', statement: 'Llegó puntualmente'  },
  { code: 'DPE_61', title: 'ACTITUDES DURANTE LA SIMULACIÓN', statement: 'Completó todas las actividades planificadas'  },
  { code: 'DPE_62', title: 'ACTITUDES DURANTE LA SIMULACIÓN', statement: 'Estuvo atento, presente durante todo el ejercicio'  },
];

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'pages',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DPE_56_ACT',
      // title: ` `,
      elements: [
        {
          type: 'matrix',
          name: 'DPE_56_ACT',
          title: `ACTITUDES DURANTE LA SIMULACIÓN`,
          isAllRowRequired: true,
          isRequired: true,
          columns: ratingValues,
          rows: items.map(it => ({ value: it.code, text: it.statement }))
        }
      ]
    }
  ]
};

export default testInfo;
