import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import DemographicsSurvey, { TestIntro } from './demographics';
import DemographicsWrapper from './demographics.styles';
import showdown from 'showdown';

class Demographics extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete
  state = {
    intro: true
  };
  onCompleteIntro = () => {
    this.setState({ intro: false });
  };

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    const { onComplete } = this.props;

    const { data } = survey;
    const parsed = {
      ...data
    };
    await onComplete(parsed);
  };

  render() {
    const { intro } = this.state;
    const IntroModel = new Survey.Model(TestIntro);
    const DemographicsModel = new Survey.Model(DemographicsSurvey);
    // setting starting data
    DemographicsModel.data = this.props.initialData || {};
    //Create showdown markdown converter
    var converter = new showdown.Converter();
    DemographicsModel.onTextMarkdown.add((survey, options) => {
      //convert the mardown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    });

    return (
      <DemographicsWrapper>
        <div className='uai_header'>
          {/* <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          /> */}
        </div>
        {intro && (
          <Survey.Survey
            model={IntroModel}
            css={CssNames}
            onComplete={this.onCompleteIntro}
          />
        )}
        {!intro && (
          <Survey.Survey
            model={DemographicsModel}
            css={CssNames}
            onComplete={this.onComplete}
          />
        )}

        {/* <div className='Footer'>

        </div> */}
        {/* <div className='PushStickyFooter'></div> */}
        <div className='Plug'>
          <a
            href='https://holos-tech.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            HOLOS
          </a>
        </div>
      </DemographicsWrapper>
    );
  }
}

export default Demographics;
