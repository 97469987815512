import React from 'react';
import MediaQuery from 'react-responsive';
import CarBrakes from './Desktop';
import CarBrakesMobile from './Mobile';
import postData from '../API';

import getConfig from '../../../config';

class DemographicsPage extends React.Component {
  state = {
    rut: localStorage.getItem(`currentCode-${this.props.BATTERY}`)
  };

  componentDidMount = async () => {
    const { updateContext, BATTERY, rut } = this.props;
    // const { rut } = this.state;
    if (updateContext) {
      // console.log('on DI test');
      // console.log({ props: this.props });
      if (rut) {
        const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
          BATTERY,
          rut
        );
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE,
          rut: CURRENT_CODE
        });
      } else {
        const { USER_ID, USER_STAGE } = await getConfig(BATTERY);
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE
        });
      }
    }
  };

  onComplete = async data => {
    const { BATTERY, rut, stage = 0 } = this.props;
    // const { rut } = this.state;
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished, STAGE: stage + 1 };

    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    const { USER_ID } = await getConfig(BATTERY, rut);
    return postData(newData, USER_ID, rut);
  };

  render() {
    return (
      <MediaQuery maxWidth={700}>
        {matches => {
          if (matches) {
            return <CarBrakesMobile onComplete={this.onComplete} />;
          } else {
            return <CarBrakes onComplete={this.onComplete} />;
          }
        }}
      </MediaQuery>
    );
  }
}

export default DemographicsPage;
