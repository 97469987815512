import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
// import theme from './theme';
// application
// import LoginPage from './Pages/Login';
// import EvaluadorPage from './Pages/Evaluador';
import EvaluadorTestPage from './Pages/EvaluadorTestPage';
// import ParticipantePage from './Pages/Participante';

import SingletonPage from './Pages/Singleton';

import { StageProvider, StageConsumer } from './Context';
import { UserProvider } from './Context/user-context';

import './index.css';
import { bhpTheme } from './common/src/theme/bhp';
import { ResetCSS } from './common/src/assets/css/style';
import { GlobalStyle } from './common/src/base.style';

const BATTERY = 'SINGLE_HOLOS_SIM';
// users sheet
const SHEET_ID = '1foutrCkHi9CoIxkB10C8FitpBKVPHH8x7YD4GZNTTdw';

// parse url query string, e.g.
// url test mode ?mode=testingMode
// url sheet ?group=Santiago+College
const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

const App = () => {
  // const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    // user: null,
    // rut: localStorage.getItem(`currentCode-${BATTERY}`),
    sheet:
      getQuery('group') ||
      localStorage.getItem(`currentSheet-${BATTERY}`) ||
      'data'
    // error: null
  });
  return (
    <UserProvider
      BATTERY={BATTERY}
      rut={localStorage.getItem(`currentCode-${BATTERY}`)}
      sheetId={SHEET_ID}
      sheetTitle={state.sheet}
    >
      <StageProvider>
        <StageConsumer>
          {({ state, nextStage }) => (
            <ThemeProvider theme={bhpTheme}>
              <>
                <ResetCSS />
                <GlobalStyle />
                <Router>
                  <>
                    <Route path='/' exact component={SingletonPage} />
                    <Route
                      path='/ev/:rut'
                      exact
                      component={EvaluadorTestPage}
                    />
                    {/* <Route path='/' exact component={LoginPage} /> */}
                    {/* <Route path='/evaluador' exact component={EvaluadorPage} /> */}
                    {/* <Route
                      path='/participante'
                      exact
                      component={ParticipantePage}
                    /> */}
                    {/* Single application */}
                    {/* <Route path='/singleton' exact component={SingletonPage} /> */}
                  </>
                </Router>
              </>
            </ThemeProvider>
          )}
        </StageConsumer>
      </StageProvider>
    </UserProvider>
  );
};
export default App;
