import React from 'react';
import Presentation from './Presentation';
import PrePresentation from './PrePresentation';
import DrivingBehavior from './DrivingBehavior';
import FinishedMessage from './FinishedMessage/NabuFinish';
import FinishedMessageFinal from './FinishedMessageFinal/NabuFinish';
import ErrorMessage from './ErrorMessage';
import postData from './API';
import getConfig from '../../config';
import VocationalWrapper from './vocacional.style';

class ParticipantPreTest extends React.Component {
  state = {
    error: ''
  };

  componentDidMount = async () => {
    // const { BATTERY, updateContext } = this.props;
    const { BATTERY, updateContext, rut } = this.props;
    // const rut = localStorage.getItem(`currentCode-${BATTERY}`);

    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const caseId = this.props.state.user;
    // const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const { BATTERY, stage, rut = 'anon', sheet = 'data' } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    // await postData(data, user, rut);
    try {
      await postData(data, caseId, rut, sheet);
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
    // if (data.CONSENT === '2') {
    //   console.log('NO CONSENT');
    //   this.props.toNextStage(stageName);
    // }
    if (stage < 3) {
      return this.props.toNextStage(stageName);
    }
  };

  onNewCase = async data => {
    // const caseId = this.props.state.user;
    // const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const { BATTERY, stage, rut = 'anon', sheet = 'data' } = this.props;
    // console.log({ rut });
    // const stageName = `userStage-${rut}-${BATTERY}`;
    const { USER_ID: newUserId } = await getConfig(BATTERY, rut, true);
    try {
      await postData({ STAGE: 0, finished: 0 }, newUserId, rut, sheet);
      window.location.replace('/');
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
  };

  render() {
    const { stage, BATTERY, user, rut, sheet, isFinishedCase } = this.props;
    // console.log({ isFinishedCase });
    // const { BATTERY, user, rut, sheet } = this.props;
    // const stage = 5;
    const { error } = this.state;
    if (!!error) {
      return <ErrorMessage error={error} />;
    }

    if (isFinishedCase) {
      return (
        <FinishedMessageFinal
          onComplete={this.onNewCase}
          name='Claudia Paez'
          email='cpaez@holos.cl'
        />
      );
    }

    if (stage > 2) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='Claudia Paez'
          email='cpaez@holos.cl'
        />
      );
    }
    if (stage === 2) {
      return (
        <DrivingBehavior
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          stage={stage}
          isLast
        />
      );
    }
    if (stage === 1) {
      return (
        <Presentation
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          user={user}
          sheet={sheet}
          stage={stage}
        />
      );
    }
    return (
      <PrePresentation
        onComplete={this.onComplete}
        BATTERY={BATTERY}
        rut={rut}
        user={user}
        sheet={sheet}
        stage={stage}
      />
    );
  }
}

const WithWrapper = props => {
  // withAuth for padding-bottom for auth details footer
  return (
    <VocationalWrapper withAuth={props.withAuth}>
      <div className='tl' />
      <div className='tr' />
      <ParticipantPreTest {...props} />
      <div className='bl' />
      <div className='br' />
    </VocationalWrapper>
  );
};

export default WithWrapper;
