import React from 'react';
import PageWrapper, { CenteredMessage } from './style';

const ErrorMessage = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <PageWrapper>
      <CenteredMessage>
        <h4>{error}</h4>
      </CenteredMessage>
    </PageWrapper>
  );
};

export default ErrorMessage;
