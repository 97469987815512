import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { androidMenu } from 'react-icons-kit/ionicons/androidMenu';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import Container from '../../common/src/components/UI/ContainerTwo';
import NavbarWrapper, { MenuArea, MobileMenu, Logo } from './navbar.style';
// import LogoImage from 'common/src/assets/image/agencyModern/logo.png';
import LogoImage from './img/uaimetrics_logo.png';

// const Navbar = () => {
//   const [mobileMenu, setMobileMenu] = useState(false);

//   const handleMobileMenu = () => {
//     setMobileMenu(!mobileMenu);
//   };

//   return (
//     <NavbarWrapper className="agencyModern-navbar navbar">
//       <Container>
//         <Logo className="main-logo"><img src={LogoImage} alt="Aprendiendo y Cuidando[Nos] en Comunidad" /></Logo>
//         {/* end of logo */}

//         <MenuArea>
//           <ul className="menu-items menu-left">
//             <li>
//               <Link to="/">Inicio</Link>
//             </li>
//             <li>
//               <Link to="/app/login">Login</Link>
//             </li>
//             <li>
//               <Link to="/app/signup">Signup</Link>
//             </li>
//           </ul>
//           {/* end of main menu */}

//           <button className="menubar" color="#0F2137"
//             variant="textButton"
//             onClick={handleMobileMenu}>

//             {mobileMenu ? (
//                 <Icon
//                   style={{ color: '#02073E' }}
//                   className="bar"
//                   size={32}
//                   icon={androidClose}
//                 />
//               ) : (
//                 <Fade>
//                   <Icon
//                     style={{ color: '#02073E' }}
//                     className="close"
//                     icon={androidMenu}
//                     size={32}
//                   />
//                 </Fade>
//               )
//             }
//           </button>
//         </MenuArea>
//       </Container>

//       {/* start mobile menu */}
//       <MobileMenu className={`mobile-menu ${mobileMenu ? 'active' : ''}`}>
//         <Container>
//           <ul className="menu">
//             <li>
//               <Link to="/">Inicio</Link>
//             </li>
//             <li>
//               <Link to="/app/login">Login</Link>
//             </li>
//             <li>
//               <Link to="/app/signup">Signup</Link>
//             </li>
//           </ul>

//         </Container>
//       </MobileMenu>
//       {/* end of mobile menu */}
//     </NavbarWrapper>
//   );
// };

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <NavbarWrapper className="agencyModern-navbar navbar">
      <Container>
        <Logo className="main-logo"><img src={LogoImage} alt="Aprendiendo y Cuidando[Nos] en Comunidad" /></Logo>
        {/* end of logo */}
      </Container>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
