import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import DemographicsSurvey from './demographics';
import DemographicsWrapper from './demographics.styles';

class Demographics extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    const { onComplete } = this.props;

    const { data } = survey;
    const { D3_GROUP, D4_GROUP, D5_GROUP, D13_GROUP } = data;
    const D13Parsed = D13_GROUP
      ? Object.keys(D13_GROUP).reduce((acc, curr) => {
          return {
            ...acc,
            [`${curr}_YEAR`]: D13_GROUP[curr].YEAR,
            [`${curr}_INFRACT`]: D13_GROUP[curr].INFRACT
          };
        }, {})
      : {};
    const parsed = {
      ...data,
      ...D3_GROUP,
      ...D4_GROUP,
      ...D5_GROUP,
      D5_SUM: parseInt(D5_GROUP.D5_01) + parseInt(D5_GROUP.D5_02),
      ...D13Parsed
    };
    // D13_01_YEAR
    // D13_01_INFRACT
    // ... D13_05_...
    delete parsed.D3_GROUP;
    delete parsed.D4_GROUP;
    delete parsed.D5_GROUP;
    delete parsed.D13_GROUP;

    await onComplete(parsed);
  };

  onValidate = async survey => {
    const { data } = survey;
    // if not consent, submit early
    if (data.CONSENT === '2') {
      const { onComplete } = this.props;
      const parsed = {
        ...data
      };
      await onComplete(parsed);
    }
  };

  render() {
    const DemographicsModel = new Survey.Model(DemographicsSurvey);

    return (
      <DemographicsWrapper>
        <div className='uai_header'>
          {/* <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          /> */}
        </div>
        <Survey.Survey
          model={DemographicsModel}
          css={CssNames}
          onComplete={this.onComplete}
          onValidatedErrorsOnCurrentPage={this.onValidate}
        />
        {/* <div className='Footer'>

        </div> */}
        {/* <div className='PushStickyFooter'></div> */}
        <div className='Plug'>
          <a
            href='https://holos-tech.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            HOLOS
          </a>
        </div>
      </DemographicsWrapper>
    );
  }
}

export default Demographics;
