import React, { useState, createContext } from 'react';
// import uuidv4 from 'uuid/v4';
// import { USER_ID } from '../config';

const { Provider, Consumer } = createContext({
  stage: 0,
  user: null,
  rut: null,
  sheet: null
});

export const StageProvider = ({ children }) => {
  const [state, setState] = useState({
    stage: 0,
    user: null,
    rut: null,
    sheet: null
  });

  const toNextStage = async (userStage = 'userStage-anon') => {
    const { stage } = state;
    const newStage = stage + 1;
    localStorage.setItem(userStage, newStage);
    await setState(prevState => ({
      ...prevState,
      stage: state.stage + 1
    }));
  };

  const toPrevStage = async (userStage = 'userStage-anon') => {
    const { stage } = state;
    const newStage = stage - 1;
    localStorage.setItem(userStage, newStage < 0 ? 0 : newStage);
    await setState(prevState => ({
      ...prevState,
      stage: state.stage - 1 < 0 ? 0 : state.stage - 1
    }));
  };

  const updateContext = async newContext => {
    await setState(prevState => ({
      ...prevState,
      ...newContext
    }));
  };

  // componentDidMount = async () => {
  //   let user;
  //   let stage;

  //   const existingId = localStorage.getItem(USER_ID);
  //   const prevStage = localStorage.getItem('userStage');
  //   // check if user on localStorage
  //   if (existingId) {
  //     user = existingId;
  //   } else {
  //     const tempUser = uuidv4();
  //     user = tempUser;
  //     localStorage.setItem(USER_ID, tempUser);
  //   }
  //   // check if stage on localStorage
  //   if (prevStage) {
  //     stage = parseInt(prevStage, 10);
  //   } else {
  //     stage = 0;
  //     localStorage.setItem('userStage', 0);
  //   }
  //   // console.log('setting');
  //   // console.log({ user, stage });
  //   await this.setState(state => ({
  //     ...state,
  //     user,
  //     stage
  //   }));
  // };

  return (
    <Provider
      value={{
        state,
        updateContext,
        nextStage: toNextStage,
        prevStage: toPrevStage
      }}
    >
      {children}
    </Provider>
  );
};

export const StageConsumer = Consumer;
