import styled from 'styled-components';
import { rgba } from 'polished';
import { themeGet } from '@styled-system/theme-get';

// import bannerImg from './img/kids_back_03.jpg';

const LoginWrapper = styled.div`
  ${'' /* background-image: url(${bannerImg}); */}
  background-color: #f1f1f1;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;

  @media only screen and (max-width: 1440px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 772px) {
    background-position: left top;
  }
`;

export const ContentWrapper = styled.div`
  ${'' /* max-width: 40%; */}
  ${'' /* display: flex; */}
  width: 100%;
  padding-top: 80px;
  padding-bottom: 6rem;

  h1 {
    font-size: 54px;
    line-height: 1.6;
    font-weight: 700;
    color: ${themeGet('colors.menu', '#02073e')};
    margin-bottom: 24px;
    letter-spacing: -2px;
    @media only screen and (max-width: 1600px) {
      font-size: 40px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 1440px) {
      margin-bottom: 15px;
      letter-spacing: -1.5px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 28px;
      margin-bottom: 20px;
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 34px;
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 23px;
      margin-bottom: 15px;
    }
  }
  .id-block {
    font-size: 0.875rem;
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    .id-heading {
      grid-column-start: 1;
      grid-column-end: -1;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    .id-item {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 100px 1fr;
      .id-label {
        color: #666;
      }
    }
  }
  .action-buttons {
    button {
      width: 100%;
      padding: 1rem 1.5rem;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors.orange};
      color: #ffffff;
      transition: 0.2s all;
      border-radius: 4px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.orange};
        transform: translateY(-2px);
      }
    }
  }
  .banner-caption {
    color: ${themeGet('colors.paragraph', '#02073E')};
    font-size: 18px;
    line-height: 2.2;
    font-weight: 400;
    margin-bottom: 0;
    @media only screen and (max-width: 1400px) {
      font-size: 16px;
      max-width: 85%;
    }
    @media only screen and (max-width: 1024px) {
      line-height: 33px;
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 768px) {
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 33px;
    }
  }
`;

export const BannerButtons = styled.div`
  display: flex;
  margin-top: 50px;
  @media only screen and (max-width: 1440px) {
    margin-top: 40px;
    width: 93%;
  }
  @media only screen and (max-width: 1024px) {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 60%;
  }
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 80%;
  }

  @media only screen and (max-width: 480px) {
    align-items: center;
    width: 100%;
    flex-direction: column;
    max-width: 100%;
  }
  .reusecore__input {
    width: 100%;
  }
  .field-wrapper {
    margin-right: 15px;
    @media only screen and (max-width: 480px) {
      margin-right: 0px;
    }
    input {
      font-family: DM Sans;
      font-size: 16px;
      min-height: 60px;
      padding: 0 24px;
      ::placeholder {
        color: ${rgba('#02073E', 0.4)};
        opacity: 1; /* Firefox */
      }
      &:focus {
        border-color: #ff825c;
      }

      @media only screen and (max-width: 1280px) {
        min-height: 50px;
      }
    }
  }
  button {
    background-color: ${({ theme }) => theme.colors.orange};
    min-width: 150px;
    @media only screen and (max-width: 480px) {
      min-width: 100%;
      margin-top: 15px;
    }
  }
`;

export default LoginWrapper;
