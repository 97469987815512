const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: 'Siguiente',
  pagePrevText: 'Atrás',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMOG_PAGE_00',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Presentación</h3>
                    <br>
                    Junto con saludarlo y agradecer su participación, le pedimos que antes de iniciar la simulación por favor complete la siguiente encuesta. El propósito de estas preguntas es conocer cuál es su experiencia y cómo ha sido su comportamiento como conductor de camiones (por ejemplo, qué tipo de errores ha cometido o cuáles son algunas dificultades que ha experimentado).
                    <br><br>
                    Contestar estas preguntas no le tomará más de 5 o 10 minutos. Es muy importante que responda de manera honesta para saber cómo ayudarlo a desarrollar sus capacidades y a tomar buenas decisiones detrás del volante. Sus respuestas serán usadas sólo de manera diagnóstica con el fin de que su instructor pueda darle sugerencias de mejora y recomendaciones.
                    <br><br>
                    Después de participar en el simulador le pediremos que conteste un segundo set de preguntas sobre su experiencia en el simulador.
                    <br><br><br>
                    De antemano, le agradecemos su tiempo y disposición.
                    <br><br>
                    Haga click en <em>Siguiente</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <span style="font-size:16px;">
                Por favor, completa los datos requeridos a continuación:
              </span>
              <br>
            </div>
          </legend>
          `
        },
        {
          type: 'text',
          name: 'NAME',
          title: 'Nombre(s):',
          isRequired: true
        },
        {
          type: 'text',
          name: 'LASTNAME',
          title: 'Apellido(s):',
          isRequired: true
        },
        {
          type: 'text',
          name: 'AGE',
          title: 'Edad (años)',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'SEX',
          title: 'Sexo',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Masculino'
            },
            {
              value: '2',
              text: 'Femenino'
            }
          ]
        }
      ]
    },

    {
      name: 'DEMOG_PAGE_02',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <span style="font-size:16px;">
                Por favor, completa los datos requeridos a continuación:
              </span>
              <br>
            </div>
          </legend>
          `
        },
        {
          type: 'text',
          name: 'D2',
          title: '¿En qué mes/año comenzó a conducir camiones? (MM/AAAA)',
          isRequired: true,
          inputType: 'masked',
          correctAnswer: '99/9999'
        },
        {
          type: 'multipletext',
          name: 'D3_GROUP',
          // isRequired: true,
          title:
            'Favor indicar la marca y el modelo del camión que más ha conducido en el último tiempo',
          items: [
            {
              name: 'D3_BRAND',
              title: 'Marca:',
              isRequired: true
            },
            {
              name: 'D3_MODEL',
              title: 'Modelo:',
              isRequired: true
            },
            {
              name: 'D3_TYPE',
              title: 'Tipo:',
              isRequired: true
            },
            {
              name: 'D3_YEAR',
              title: 'Año:',
              isRequired: true
            }
          ]
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_03',
      elements: [
        {
          type: 'multipletext',
          name: 'D4_GROUP',
          // isRequired: true,
          title:
            'EXCLUYENDO el camión que mencionó en relación a la pregunta anterior, favor indicar la marca y modelo de otros camiones que ha conducido y con los que se siente familiarizado. (No es necesario completar todas las opciones)',
          items: [
            {
              name: 'D4_01',
              title: 'Marca, Modelo:'
              // isRequired: true
            },
            {
              name: 'D4_02',
              title: 'Marca, Modelo:'
              // isRequired: true
            },
            {
              name: 'D4_03',
              title: 'Marca, Modelo:'
              // isRequired: true
            },
            {
              name: 'D4_04',
              title: 'Marca, Modelo:'
              // isRequired: true
            },
            {
              name: 'D4_05',
              title: 'Marca, Modelo:'
              // isRequired: true
            }
          ]
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_04',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'multipletext',
          name: 'D5_GROUP',
          title:
            'En una semana normal, ¿cuántos kilómetros en promedio conduce en carretera y en zonas residenciales/urbanas? [Ingrese número de kilómetros aproximados en cada casillero]',
          isRequired: true,
          items: [
            {
              name: 'D5_01',
              isRequired: true,
              inputType: 'number',
              title: 'Kilómetros en carretera:',
              validators: [
                {
                  type: 'numeric',
                  // text: 'Ingrese un kilometro valido',
                  minValue: 0,
                  maxValue: 10000
                }
              ]
            },
            {
              name: 'D5_02',
              isRequired: true,
              inputType: 'number',
              title: 'Kilómetros en zona residencial/urbana:',
              validators: [
                {
                  type: 'numeric',
                  // text: 'Ingrese un kilometro valido',
                  minValue: 0,
                  maxValue: 10000
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_D6',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'comment',
          name: 'D6',
          title:
            '¿Qué cursos de formación/especialización en la conducción de camiones ha tomado?',
          isRequired: true
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_D7',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'text',
          name: 'D7',
          title:
            '¿Cuántos accidentes de tránsito ha tenido que haya resultado en una FATALIDAD?',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        },
        {
          type: 'text',
          name: 'D8',
          title:
            '¿En cuántos de estos accidentes con resultado de muerte ha sido usted el responsable?',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_D9',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'text',
          name: 'D9',
          title:
            'EXCLUYENDO los accidentes reportados en la pregunta anterior ¿Cuántos accidentes de tránsito ha tenido en que una persona haya resultado HERIDA FÍSICAMENTE?',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        },
        {
          type: 'text',
          name: 'D10',
          title:
            '¿En cuántos de estos accidentes con personas heridas físicamente ha sido usted el responsable?',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_D11',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'text',
          name: 'D11',
          title:
            'EXCLUYENDO los accidentes reportados en las preguntas anteriores ¿Cuántos accidentes de tránsito han resultado en daños a la propiedad?',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        },
        {
          type: 'text',
          name: 'D12',
          title:
            '¿En cuántos de estos accidentes con daños a la propiedad ha sido usted el responsable?',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_D13',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'matrixdropdown',
          name: 'D13_GROUP',
          title:
            'Favor enumere a continuación las multas de tránsito que haya recibido desde 2015 a la fecha. Por ejemplo, si el 2015 fue sancionado por conducir a exceso de velocidad debe indicar en año “2015” y en tipo de infracción “Conducir a exceso de velocidad”',
          columns: [
            {
              name: 'YEAR',
              title: 'Año',
              cellType: 'dropdown',
              choices: [
                {
                  value: '2015',
                  text: '2015'
                },
                {
                  value: '2016',
                  text: '2016'
                },
                {
                  value: '2017',
                  text: '2017'
                },
                {
                  value: '2018',
                  text: '2018'
                },
                {
                  value: '2019',
                  text: '2019'
                },
                {
                  value: '2020',
                  text: '2020'
                },
                {
                  value: '2021',
                  text: '2021'
                }
              ]
            },
            {
              name: 'INFRACT',
              title: 'Tipo de Infracción',
              cellType: 'text'
            }
          ],
          cellType: 'text',
          rows: [
            {
              value: 'D13_01',
              text: '01.- '
            },
            {
              value: 'D13_02',
              text: '02.- '
            },
            {
              value: 'D13_03',
              text: '03.- '
            },
            {
              value: 'D13_04',
              text: '04.- '
            },
            {
              value: 'D13_05',
              text: '05.- '
            },
            {
              value: 'D13_06',
              text: '06.- '
            },
            {
              value: 'D13_07',
              text: '07.- '
            },
            {
              value: 'D13_08',
              text: '08.- '
            },
            {
              value: 'D13_09',
              text: '09.- '
            },
            {
              value: 'D13_10',
              text: '10.- '
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
