// eslint-disable-next-line
import React from 'react';
// In your react App.js or yourComponent.js file add these lines to import
import * as SurveyReact from 'survey-react';
import * as Widgets from 'surveyjs-widgets';
import './locale';
import cssNames from './cssNames';
import '../../styles/main.scss';
import init from './widgets';
// register widgets
init(SurveyReact);
Widgets.sortablejs(SurveyReact);
// const { Survey: S } = SurveyReact;
// const StyledSurvey = props => <S css={cssNames} {...props} />;
// SurveyReact.Survey = StyledSurvey;
export const CssNames = cssNames;
// SurveyReact.applyTheme('default');
// SurveyReact.StylesManager.applyTheme('bootstrap');
// console.log({ styles: SurveyReact });
// export default { ...SurveyReact, Survey: StyledSurvey };
export default SurveyReact;
