import React from 'react';
import ChatWrapper from './chat.style';
import BHPLogo from './img/BHP_orange.png';

const NabuIntro = props => {
  const { name, email, BATTERY, rut, onComplete } = props;

  const handleResetProgress = async () => {
    // console.log('remove progress from');
    const USER_ID = `userId-${rut}-${BATTERY}`;
    const USER_STAGE = `userStage-${rut}-${BATTERY}`;
    // console.log({ USER_ID, USER_STAGE });
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_STAGE);
    // window.location.replace('/');
    await onComplete({})
  };

  return (
    <ChatWrapper>
      <div className='nabu-chat-person'>
        <img className='nabu-pic' src={BHPLogo} alt='nabu-pic' />
      </div>
      <div className='nabu-chat-wrapper'>
        <div className='finish_main'>
          <h3>Ha finalizado esta encuesta</h3>
          <p>
            La evaluación han sido ingresada de forma exitosa.
            <br />
            <br />
          </p>
          <button
            onClick={handleResetProgress}
            className='primary-btn'
            type='button'
          >
            Responder Cuestionario Nuevamente
          </button>
          <div>
            <small>Iniciará una nueva encuesta desde el comienzo</small>
          </div>
        </div>
      </div>
    </ChatWrapper>
  );
};

export default NabuIntro;
