const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: 'Siguiente',
  pagePrevText: 'Atrás',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMOG_PAGE_00',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'radiogroup',
          name: 'FEED_01',
          title:
            '¿Le recomendaría a otros conductores como usted participar en el simulador?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Si'
            },
            {
              value: '2',
              text: 'No'
            }
          ]
        }
      ]
    },

    {
      name: 'DEMOG_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'comment',
          name: 'FEED_02',
          title:
            'Gracias por su participación. Para finalizar, le agradecermos nos pueda dar un feedback respecto de su experiencia con el simulador incluyendo tanto aspectos positivos como oportunidades para mejorar esta herramienta.',
          isRequired: true
        }
      ]
    }
  ]
};

export default baseInfo;
