import React, { useState, useEffect } from 'react';
// import SingleProcessComponent from '../../Components/SingleProcess';
import SingleSwitchComponent from '../../Components/SingleSwitch';
import TestingMenu from '../../Components/TestingMenu';
import { StageConsumer } from '../../Context';
import { UserProvider, UserConsumer } from '../../Context/user-context';
// auth components
import AuthRutId from '../../Components/Auth/Single';
import LogInForm from '../../Components/Auth/Single/LogInForm';
import ModalSelector from '../../Components/Auth/Single/ModalSelector';
// import logIn from '../../Components/Auth/Single/login';

const BATTERY = 'SINGLE_HOLOS_SIM';
// users sheet
const SHEET_ID = '1foutrCkHi9CoIxkB10C8FitpBKVPHH8x7YD4GZNTTdw';

// parse url query string, e.g.
// url test mode ?mode=testingMode
// url sheet ?group=Santiago+College
const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

const SingleTestPage = props => {
  // const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    // user: null,
    // rut: localStorage.getItem(`currentCode-${BATTERY}`),
    sheet:
      getQuery('group') ||
      localStorage.getItem(`currentSheet-${BATTERY}`) ||
      'data'
    // error: null
  });

  // useEffect(() => {
  //   const loginUser = async () => {
  //     const { rut, sheet } = state;
  //     const online = true;
  //     // const cleanedRut = rut.replace(/_/g, '').replace(/\./g, '');
  //     const cleanedRut = rut.replace(/_/g, '');
  //     const user = await logIn(cleanedRut, SHEET_ID, sheet, online);
  //     // console.log({user})
  //     if (!user) {
  //       await setLoading(false)
  //       await setState(prev => ({...prev, error: `RUT. ${cleanedRut} no existe o no está validado en ${sheet}`}));
  //     }
  //     if(user){
  //       // if user, set on state
  //       await setState(prev => ({...prev, user, error: null }));
  //       await setLoading(false)
  //     }
  //   }
  //   const { rut } = state;
  //   if (!rut) {
  //     setLoading(false);
  //   }
  //   // if(rut){
  //   //   loginUser()
  //   // }
  // }, [state])

  // componentDidMount = async () => {
  //   const { rut, sheet } = this.state;
  //   if (!rut) {
  //     return this.setState({ loading: false });
  //   }
  //   const online = true;
  //   // const cleanedRut = rut.replace(/_/g, '').replace(/\./g, '');
  //   const cleanedRut = rut.replace(/_/g, '');
  //   const user = await logIn(cleanedRut, SHEET_ID, sheet, online);
  //   console.log({user})
  //   if (!user) {
  //     return this.setState({
  //       error: `RUT. ${cleanedRut} no existe o no está validado en ${sheet}`,
  //       loading: false
  //     });
  //   }
  //   // if user, set on state
  //   return this.setState({ user, error: null, loading: false });
  // };

  // const { user, rut, error, sheet } = state;
  const { sheet } = state;
  // console.log({ sheet });
  // console.log({ user, rut, error });
  const appMode = getQuery('mode');
  const isTestingMode = appMode === 'testingMode';
  // console.log({ appMode, isTestingMode });
  const testing = isTestingMode; // true;
  const multiple = true;

  // console.log({ withAuth });
  return (
    <UserProvider
      BATTERY={BATTERY}
      rut={localStorage.getItem(`currentCode-${BATTERY}`)}
      sheetId={SHEET_ID}
      sheetTitle={sheet}
    >
      <UserConsumer>
        {({ user, rut, error, isLoading }) => {
          const withAuth = !!user;
          return (
            <StageConsumer>
              {({ state, updateContext, nextStage, prevStage }) => {
                if (isLoading) {
                  return <div>Loading...</div>;
                }
                if (!user) {
                  return (
                    <div>
                      <LogInForm
                        {...props}
                        {...state}
                        updateContext={updateContext}
                        BATTERY={BATTERY}
                        sheet={sheet}
                        error={error}
                      />
                    </div>
                  );
                }
                if (user) {
                  return (
                    <React.Fragment>
                      <SingleSwitchComponent
                        {...props}
                        {...state}
                        state={state}
                        updateContext={updateContext}
                        toNextStage={nextStage}
                        BATTERY={BATTERY}
                        withAuth={withAuth}
                        sheet={sheet}
                        rut={rut}
                        user={user}
                      />
                      {testing && (
                        <TestingMenu
                          {...state}
                          BATTERY={BATTERY}
                          toNextStage={nextStage}
                          toPrevStage={prevStage}
                          rut={rut}
                          user={user}
                          // equis2
                        />
                      )}
                      {multiple && (
                        <AuthRutId
                          {...props}
                          {...state}
                          // updateContext={updateContext}
                          BATTERY={BATTERY}
                          rut={rut}
                          user={user}
                        />
                      )}
                      {multiple && !rut && (
                        <ModalSelector
                          {...state}
                          updateContext={updateContext}
                          BATTERY={BATTERY}
                          active={!rut}
                          rut={rut}
                          user={user}
                        />
                      )}
                    </React.Fragment>
                  );
                }
                return <div></div>;
              }}
            </StageConsumer>
          );
        }}
      </UserConsumer>
    </UserProvider>
  );
};

export default SingleTestPage;
