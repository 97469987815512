const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: 'Siguiente',
  pagePrevText: 'Atrás',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMOG_PAGE_00',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Confirmación</h3>
                    <br>
                    <br>
                    Espere a instrucciones del evaluador para continuar con la segunda parte de esta actividad
                    <br><br>
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    A continuación, deberá responder una serie de cuestionarios en relación con la actividad que <strong>acaba de realizar</strong>.
                    <br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

export default baseInfo;
