import React from 'react';
import EvaluadorView from '../Evaluador';
// import EvaluadorView from '../EvaluadorTest';
import ParticipanteView from '../Participante';
// import ParticipanteView from '../ParticipantPost';

const EVALUADOR = 'evaluador';

const SingleSwitch = props => {
  const { user } = props;
  // console.log('on switch')
  // console.log({user})
  if (user.role === EVALUADOR) {
    return <EvaluadorView {...props} />;
  }
  return <ParticipanteView {...props} />;
};

export default SingleSwitch;
