const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    Lea cada una de las siguientes afirmaciones e indique su grado de acuerdo con cada una de ellas seleccionando la opción que mejor lo represente.
                    <br>
                    Para hacer esto, seleccione la opción correspondiente (1 = <em>Muy en desacuerdo</em>; 2 = <em>En desacuerdo</em>; 3 = <em>Ni de acuerdo ni en desacuerdo</em>; 4 = <em>De acuerdo</em>; 5 = <em>Muy de acuerdo</em>).
                    <br><br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

const items = [
  {
    code: 'REACT_01',
    statement: 'Usar el simulador significó un gran esfuerzo mental'
  },
  { code: 'REACT_02', statement: 'Aprender a usar el simulador fue agotador' },
  {
    code: 'REACT_03',
    statement:
      'Fue fácil lograr que el camión hiciera lo que yo quería durante el juego'
  },
  {
    code: 'REACT_04',
    statement:
      'La dificultad para usar el simulador fue un obstáculo para aprender sobre estos temas'
  },
  {
    code: 'REACT_05',
    statement:
      'Este simulador es útil para aprender a conducir de manera segura'
  },
  {
    code: 'REACT_06',
    statement:
      'Sería más útil aprender sobre cómo conducir de manera segura usando otro método'
  },
  { code: 'REACT_07', statement: 'Siento que aprendí mucho en el simulador' },
  {
    code: 'REACT_08',
    statement: 'Me gustaría seguir aprendiendo de esta manera en el futuro'
  },
  { code: 'REACT_09', statement: 'Usar este simulador fue motivante' },
  { code: 'REACT_10', statement: 'Disfruté usar el simulador' },
  { code: 'REACT_11', statement: 'Usar el simulador fue entretenido' },
  {
    code: 'REACT_12',
    statement:
      'Lo que se puede aprender en este simulador es transferible a situaciones reales'
  }
];

const questionFooter = {
  type: 'html',
  name: 'HTML_QUESTION_FOOTER',
  html: `
  <div class="qstn-likert-label pb-5">
    <div>Muy en desacuerdo</div>
    <div></div>
    <div>Muy de acuerdo</div>
  </div>
  `
};

const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_HEADER',
  html: `
  <legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
          1 = <em>Muy en desacuerdo</em>; 2 = <em>En desacuerdo</em>; 3 = <em>Ni de acuerdo ni en desacuerdo</em>; 4 = <em>De acuerdo</em>; 5 = <em>Muy de acuerdo</em>.
        </span>
      </span>
    </div>
    <br>
  </div>
</legend>
  `
};

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: items.map(item => {
    const { code, statement } = item;
    return {
      name: code,
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: code,
          title: statement,
          isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    };
  })
};

export default testInfo;
