const base = {
  red: '#dc3545',
  green: '#50a893', // bhp light green
  // yellow: '#fdb32a',
  yellow: '#f6c047', // bhp yellow
  yellowHover: '#F29E02',
  orange: '#E65400', // bhp orange ee7a33
  lightOrange: '#ee8233',
  blue: '#29558f', // bhp blue
  lightBlue: '#429ab6', // bhp light blue
  black: '#252525', // bhp black
  dark: '#252525', // bhp black
  grey: '#6c757d',
  white: '#ffffff',
  extraLight: '#fefefe',
  light: '#f1f1f1',
  disabled: '#adb5bd',
  dangerLight: '#fff4f5'
};
const colors = {
  ...base,
  transparent: 'transparent',
  lightGray: '#F0F0F0',
  primary: '#FCF22B',
  primaryHover: '#ECF22F',
  primary2: 'rgba(252, 242, 43, 0.25)',
  secondary: '#D50032',
  secondary2: '#EE2541',
  secondaryHover: '#FF282F',
  border: '#dadada',
  backgroundBase: '#f2f0e9', // bhp back base
  heading: '#060F1E',
  text: '#294859',
  secondaryText: '#616970',
  lightText: 'rgba(41, 72, 89, 0.5)',
  linkedin: '#0077B5',
  facebook: '#3b5998',
  twitter: '#38A1F3',
  github: '#211f1f',
  select: '#525F7F',
  //
  success: base.green,
  danger: base.red,
  warning: base.yellow,
  textColor: '#43464d',
  darkBackground: '#1d1e20',
  greyBackground: '#2d2d2d',
  brandColor: '#009fe3',
  brandSolid: '#0076e3',
  itemBackground: 'rgba(0, 0, 0, 0.06)',
  itemBackgroundHover: 'rgba(0, 0, 0, 0.11)'
};

export default colors;
