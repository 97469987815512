const ratingValues = [
  {
    value: '1',
    text: 'Si'
  },
  {
    value: '2',
    text: 'No'
  },
  {
    value: '3',
    text: 'No aplica'
  },
];

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    A continuación se le presentara una serie de afirmaciones que debe responder en relación a la persona que está siendo evaluada, en base a las alternativas <em>Si</em>, <em>No</em> y <em>No aplica</em>.
                    <br>
                    Por favor, seleccione la opción que corresponda para cada caso.
                    <br><br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

const items = [
  { code: 'DPE_14', title: 'FRENADO Y REDUCCIÓN DE VELOCIDAD', statement: 'Frena tan pronto cuando surge la necesidad'  },
  { code: 'DPE_15', title: 'FRENADO Y REDUCCIÓN DE VELOCIDAD', statement: 'Evita frenar de manera repentina'  },
  { code: 'DPE_16', title: 'FRENADO Y REDUCCIÓN DE VELOCIDAD', statement: 'Se detiene con suavidad, sin patinar'  },
  { code: 'DPE_17', title: 'FRENADO Y REDUCCIÓN DE VELOCIDAD', statement: 'Chequea los frenos el la cima de una subida'  },
  { code: 'DPE_18', title: 'FRENADO Y REDUCCIÓN DE VELOCIDAD', statement: 'Baja los cambios para descender'  },
  { code: 'DPE_19', title: 'FRENADO Y REDUCCIÓN DE VELOCIDAD', statement: 'Usa el freno de manera adecuada al descender'  },
  { code: 'DPE_20', title: 'FRENADO Y REDUCCIÓN DE VELOCIDAD', statement: 'Chequea las válvulas de aire'  },
];

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: items.map(item => {
    const { code, title, statement } = item;
    return {
      name: code,
      title: title,
      elements: [
        {
          type: 'rating',
          name: code,
          title: statement,
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    };
  })
};

export default testInfo;
