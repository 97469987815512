const ratingValues = [
  {
    value: '1',
    text: 'Si'
  },
  {
    value: '2',
    text: 'No'
  },
  {
    value: '3',
    text: 'No aplica'
  },
];

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    A continuación se le presentara una serie de afirmaciones que debe responder en relación a la persona que está siendo evaluada, en base a las alternativas <em>Si</em>, <em>No</em> y <em>No aplica</em>.
                    <br>
                    Por favor, seleccione la opción que corresponda para cada caso.
                    <br><br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

const items = [
  { code: 'DPE_01', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Chequea los espejos'  },
  { code: 'DPE_02', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Pone la transmisión en neutro'  },
  { code: 'DPE_03', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Pasa los cambios suavemente'  },
  { code: 'DPE_04', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Arranca el motor sin dificultad'  },
  { code: 'DPE_05', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Espera que el motor se caliente'  },
  { code: 'DPE_06', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Deja que se forme presión de aire antes de salir'  },
  { code: 'DPE_07', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Chequea los frenos a 30 metros de la partida'  },
  { code: 'DPE_08', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Chequea y entiende los instrumentos'  },
  { code: 'DPE_09', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Usa la palanca de cambio de forma apropiada'  },
  { code: 'DPE_10', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Mantiene un RPM adecuado'  },
  { code: 'DPE_11', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Pasa los cambios cuando corresponde'  },
  { code: 'DPE_12', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Usa los cambios en la secuencia adecuada'  },
  { code: 'DPE_13', title: 'PONER AL VEHÍCULO EN MOVIMIENTO', statement: 'Usa el cinturón de seguridad'  },
];


const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'pages',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DPE_01_MOVE',
      // title: ` `,
      elements: [
        {
          type: 'matrix',
          name: 'DPE_01_MOVE',
          title: `PONER AL VEHÍCULO EN MOVIMIENTO`,
          isAllRowRequired: true,
          isRequired: true,
          columns: ratingValues,
          rows: items.map(it => ({ value: it.code, text: it.statement }))
        }
      ]
    }
  ]
};

export default testInfo;
