import { createContext, useState, useEffect, useContext } from 'react';
import logInGoogleSheet from '../Components/Auth/Single/login';
import { isRut } from '../common/src/utils/validator';

const INITIAL_VALUES = {
  BATTERY: null,
  user: null,
  rut: null,
  sheetId: null,
  sheetTitle: null,
  error: null
};

const UserContext = createContext(INITIAL_VALUES);

export const UserProvider = props => {
  const { children } = props;
  const [state, setState] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log('updating initial response');
    // console.log({ poll });
    setState(prev => ({
      ...prev,
      BATTERY: props.BATTERY,
      rut: props.rut,
      sheetId: props.sheetId,
      sheetTitle: props.sheetTitle
    }));
  }, [props]);

  useEffect(() => {
    const getLocalUser = () => {
      const BATTERY_USER = `user-${props.BATTERY}` || null;
      const storageUser = localStorage.getItem(BATTERY_USER);
      // console.log({storageUser})
      if (!storageUser) {
        return null;
      }
      const localUser = JSON.parse(storageUser);
      return localUser;
    };
    const localUser = getLocalUser();
    if (localUser) {
      setState(prev => ({ ...prev, user: localUser }));
    }
  }, []);

  // useEffect(() => {
  //   console.log('on data context results');
  //   async function getRespData() {
  //     const data = await axiosFetcher(`/api/poll/r/${poll.id}`, {
  //       accessKey: getLocalAccessKey(slug)
  //     });
  //     // console.log({ data });
  //     // get data
  //     if (data) {
  //       await setResponses(data.responses);
  //     }
  //     await setLoading(false);
  //   }
  //   getRespData();
  // }, []);

  const logIn = async ({ rut }) => {
    await setLoading(true);
    const { BATTERY, sheetId, sheetTitle } = state;
    const online = true;
    // console.log({ state });
    // const cleanedRut = rut.replace(/_/g, '').replace(/\./g, '');
    const cleanedRut = rut.replace(/_/g, '');
    const { valid, parsedRut, message } = isRut(cleanedRut);
    // console.log({ valid, parsedRut, message });
    if (!valid) {
      await setState(prev => ({
        ...prev,
        error: `RUT. ${cleanedRut} no es válido.`
        // error: `RUT. ${cleanedRut} no existe o no está validado en ${sheetTitle}`
      }));
      return setLoading(false);
    }
    const user = await logInGoogleSheet(
      cleanedRut,
      sheetId,
      sheetTitle,
      online
    );
    // console.log({user})
    if (!user) {
      await setState(prev => ({
        ...prev,
        error: `RUT. ${cleanedRut} no existe o no está validado.`
        // error: `RUT. ${cleanedRut} no existe o no está validado en ${sheetTitle}`
      }));
      await setLoading(false);
    }
    // console.log({user})
    if (user) {
      // if user, set local data
      const CURRENT_CODE = `currentCode-${BATTERY}`;
      const CURRENT_SHEET = `currentSheet-${BATTERY}`;
      const BATTERY_USER = `user-${BATTERY}`;
      localStorage.setItem(CURRENT_CODE, cleanedRut);
      localStorage.setItem(CURRENT_SHEET, sheetTitle);
      localStorage.setItem(BATTERY_USER, JSON.stringify(user));
      // set state
      await setState(prev => ({ ...prev, user, rut: user.rut, error: null }));
      await setLoading(false);
    }
  };
  const logOut = async () => {
    const { BATTERY } = state;
    const CURRENT_CODE = `currentCode-${BATTERY}`;
    const BATTERY_USER = `user-${BATTERY}`;
    localStorage.removeItem(CURRENT_CODE);
    localStorage.removeItem(BATTERY_USER);
    await setState(prev => ({
      ...prev,
      rut: null,
      user: null
    }));
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        isLoading: loading,
        logIn,
        logOut
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
export const useUserContext = () => useContext(UserContext);
