import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ParticipantPre from '../ParticipantPre';
import ParticipantPost from '../ParticipantPost';
import FinishedMessageReset from '../ParticipantPost/FinishedMessage/NabuFinish';

const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

const ParticipantComponent = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [participant, setParticipant] = useState(null);
  const { rut } = props;

  const appMode = getQuery('mode');
  const isResetMode = appMode === 'reset';

  // console.log({ props });
  useEffect(() => {
    async function getParticipant() {
      try {
        const body = {
          params: {
            query: {
              field: 'RUT',
              value: rut
            },
            s_id: '1PeIqgTIf7bKB-zVpmDFzul9BDEj11ubfZ8uAXVgnWNE',
            sheet: 'data'
          }
        };
        const { data } = await axios.get('/api/get-responses', body);
        const { users } = data;
        const userNotFinished = users.find(user => user.finished === '0' || !user.finished);
        const finishedCase = users.find(user => user.finished === '1');
        const hasPrevResponse = finishedCase && !userNotFinished;
        const prevParticipantData = hasPrevResponse ? { ...finishedCase, new: true } : {};
        // console.log({ users });
        // console.log({ userNotFinished });
        await setParticipant(userNotFinished || prevParticipantData);
        await setLoading(false);
      } catch (err) {
        console.log('Failed:');
        console.log({ err });
        await setError('No hay participante con este rut');
        await setLoading(false);
      }
    }
    getParticipant();
  }, [rut]);

  // console.log({ participant });
  if (loading) {
    return <div>Cargando...</div>;
  }
  if (isResetMode) {
    return <FinishedMessageReset {...props} BATTERY='SINGLE_HOLOS_SIM' />;
  }
  if (participant && participant.PRE === '1' && !participant.new) {
    return <ParticipantPost {...props} BATTERY='SINGLE_HOLOS_SIM' />;
  }
  return <ParticipantPre {...props} isFinishedCase={participant.new} BATTERY='SINGLE_HOLOS_SIM' />;
};

export default ParticipantComponent;
