import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled, { css } from 'styled-components';
// import InputMask from 'react-input-mask';
import Inputmask from 'inputmask';
import { isRut } from '../../../common/src/utils/validator';
// import { isRut } from '../../../common/src/utils/validator';
// import { windowRedirect } from 'common/src/utils/helpers';
// import { setUser } from 'src/utils/auth';
// import HolosLogo from './img/holos_logo.png';
// import BHPLogo from './img/BHP_orange.png';

const Warning = styled.div`
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  font-weight: 400;
  color: #7d7c7c;
  text-transform: uppercase;
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;

const LoginFormWrapper = styled.div`
  position: relative;
  ${
    '' /* display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 85vh; */
  }
  background: ${({ theme }) => theme.colors.background};

.login-heading {
  .logo {
    text-align: center;
    max-width: 100px;
    margin: auto;
    img {
      width: 100%;
      margin: 0;
      object-fit: contain;
    }
  }
  h1 {
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
}
.login-footer {
  .logo {
    text-align: center;
    max-width: 150px;
    margin: auto;
    padding: 1rem;
    img {
      width: 100%;
      margin: 0;
      object-fit: contain;
    }
  }

}
  .app-login {
    z-index: 1;
    width: 60vw;
    max-width: 500px;
    margin: auto;
    padding: 1.5rem 3rem;
    background: white;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
    @media (max-width: 900px) {
      width: 65vw;
      padding: 2rem 1.5rem;
    }
    @media (max-width: 574px) {
      width: 80vw;
      padding: 2rem 1.5rem;
    }
    .login-title {
      ${'' /* padding: 0rem 1rem 1.5rem; */}
      padding: 1rem;
    padding-bottom: 0;
      font-size: 0.85rem;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.black};
      text-transform: uppercase;
      text-align: center;
    }
    form {
      label {
        font-size: 0.65rem;
        font-weight: 500;
        color: #7d7c7c;
        text-transform: uppercase;
        min-width: 100px;
        padding: 0rem 1.5rem;
        margin-bottom: 0;
      }
      .field-wrapper {
        padding: 0.5rem 1rem;
        .app-input {
          width: 100%;
        }
        input {
          font-size: 0.85rem;
          color: #666;
          border: none;
          background-color: #f1f1f1;
          padding: 0.875rem 1.5rem;
          border-radius: 2px;
        }
        button {
          padding: 0.875rem 1.5rem;
          border-radius: 2px;
          text-transform: uppercase;
          font-weight: 600;
          width: 100%;
          font-size: 0.85rem;
          background-color: ${({ theme }) => theme.colors.orange};
          color: ${({ theme }) => theme.colors.white};
          border: none;
          cursor: pointer;
          &:disabled {
            background-color: grey !important;
          }
        }
        &.error {
          text-align: center;
          background-color: #f8d7da;
        }
      }
    }
    .login-form-switch {
      text-align: center;
      color: #7d7c7c;
      font-size: 0.8rem;
      padding: 0.5rem;
    }
  }
`;
const RutInputWrapper = styled.div`
  ${({ valid, theme }) =>
    valid &&
    css`
      input {
        border: 1px solid ${theme.colors.success} !important;
      }
    `};
  ${({ error, theme }) =>
    error &&
    css`
      input {
        border: 1px solid ${theme.colors.danger} !important;
      }
    `};
`;

const Form = props => {
  // console.log({ props });
  // const { sheetId, sheetTitle } = props;
  const [loading, setLoading] = useState(false);
  const [rut, setRut] = useState('');
  const [participant, setParticipant] = useState(null);
  const [validRut, setValidRut] = useState(null);
  const [error, setError] = useState('');
  const inputEl = useRef(null);
  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    Inputmask({ mask: '99.999.999-9', numericInput: true }).mask(inputEl);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    await setLoading(true);
    await setError('');
    await setValidRut(null);
    if (!rut) {
      await setLoading(false);
      return setError('*Por favor, ingrese los datos requeridos');
    }
    // // console.log({ valid, parsedRut, message });
    // await setValidRut(valid);
    // if (!valid) {
    //   await setLoading(false);
    //   return setError(message);
    // }
    const cleanedRut = rut.replace(/_/g, '');
    const { valid, parsedRut, message } = isRut(cleanedRut);
    // console.log({ valid, parsedRut, message });
    try {
      const body = {
        params: {
          query: {
            field: 'RUT',
            value: cleanedRut
          },
          s_id: '1PeIqgTIf7bKB-zVpmDFzul9BDEj11ubfZ8uAXVgnWNE',
          sheet: 'data'
        }
      };
      const { data } = await axios.get('/api/get-user', body);
      const { user } = data;
      // console.log({ user });
      await setParticipant(user);
      await setLoading(false);
      if (!user) {
        await setError(
          `No hay registros con rut: ${cleanedRut}.${
            valid
              ? ''
              : ' El rut ingresado NO es válido. Revisar dato e ingresar nuevamente'
          }`
        );
      }
    } catch (err) {
      console.log('Failed:');
      console.log({ err });
      await setLoading(false);
      await setError(
        `No hay participante con rut: ${cleanedRut}.${
          valid
            ? ''
            : ' El rut ingresado NO es válido. Revisar dato e ingresar nuevamente'
        }`
      );
    }
  };

  // console.log({ participant });
  return (
    <LoginFormWrapper>
      <div className='app-login'>
        <div className='login-title'>Ingrese RUT a evaluar</div>
        <form onSubmit={onSubmit}>
          <label htmlFor='rutid'>RUT</label>
          {/* <Input
            inputType="text"
            name="rutid"
            placeholder="Ingresa tu número de Rut"
            iconPosition="right"
            isMaterial={false}
            className="app-input"
            aria-label="Ingresa tu Rut"
            onChange={(e) => setRut(e.target.value)}
            disabled={loading}
            value={rut}
          /> */}
          <RutInputWrapper valid={validRut} error={validRut === false}>
            <div className='field-wrapper'>
              <input
                ref={inputEl}
                name='rutid'
                placeholder='Ingresa tu número de Rut'
                value={rut}
                onChange={e => setRut(e.target.value)}
                className='app-input'
                aria-label='Ingresa tu Rut'
                disabled={loading}
              />
              {/* <InputMask
                type='text'
                name='rutid'
                placeholder='Ingresa tu número de Rut'
                mask='99.999.999-*'
                className='app-input'
                aria-label='Ingresa tu Rut'
                onChange={e => setRut(e.target.value)}
                disabled={loading}
                value={rut}
                onBlur={e => {
                  const noBlacks = e.target.value.replace(/_/g, '');
                  const noDots = noBlacks.replace(/\./g, '');
                  const noDash = noDots.replace(/-/g, '');
                  // console.log({ values: noDash, lenght: noDash.length });
                  // console.log(e.target.value);
                  if (noDash.length === 8) {
                    // meaning, finish with 1 char empty. Add empty to first value
                    setRut(`0${e.target.value}`);
                  }
                }}
              /> */}
            </div>
          </RutInputWrapper>

          <div className='field-wrapper'>
            <button
              type='submit'
              disabled={loading}
              colors={loading ? 'disabledWithBg' : 'primaryWithBg'}
            >
              {loading ? 'Buscando...' : 'Buscar'}
            </button>
          </div>
          {error && (
            <div className='field-wrapper error'>
              <Warning>{error}</Warning>
            </div>
          )}
        </form>

        {/* <br /> */}
        <div className='login-form-switch'>
          No encuentra un rut? <a href='mailto:cpaez@holos.cl'>Escríbenos!</a>
        </div>

        {participant && (
          <>
            <div className='id-block'>
              <div className='id-heading'>Datos Participante:</div>
              <div className='id-item'>
                <div className='id-label'>Rut:</div>
                <div className='id-value'>{participant.RUT}</div>
              </div>
              <div className='id-item'>
                <div className='id-label'>Nombre(s)</div>
                <div className='id-value'>{participant.NAME}</div>
              </div>
              <div className='id-item'>
                <div className='id-label'>Apellido(s):</div>
                <div className='id-value'>{participant.LASTNAME}</div>
              </div>
            </div>
            <div className='action-buttons'>
              <div className='btn-wrapper'>
                <Link to={`/ev/${participant.RUT}`}>
                  <button type='button'>
                    Evaluar a {`RUT: ${participant.RUT}`}
                  </button>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </LoginFormWrapper>
  );
};

export default Form;
