import axios from 'axios';
// import { USER_ID } from '../config';

// function removeDiacritics(text) {
//   return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
// }

const postData = async (values, USER_ID, CODE, sheet) => {
  // const POST_API =
  //   'https://iathnyvk8h.execute-api.us-west-2.amazonaws.com/dev/submit';
  const POST_API = '/api/post-data';

  // const normalized_string = removeDiacritics(sheet);
  // // 'Universidad Adolfo Ibáñez' => 'Universidad Adolfo Ibanez'
  // // console.log({ normalized_string });
  // const cleaned_sheet = normalized_string
  //   .toLowerCase()
  //   .trim()
  //   .replace(/ /g, '-');
  // console.log({ cleaned_sheet });
  // 'Universidad Adolfo Ibanez' => universidad-adolfo-ibanez
  // console.log({ cleaned_sheet });
  const config = {
    SHEET_ID: '1PeIqgTIf7bKB-zVpmDFzul9BDEj11ubfZ8uAXVgnWNE',
    SHEET_TITLE: 'evaluador'
  };

  const caseid = USER_ID;
  const data = { ...values, caseid, RUT: CODE, config };
  // console.log({ data });
  await axios.post(POST_API, data);
  return Promise.resolve('200');
};

export default postData;
