import styled from 'styled-components';

const TestingMenuWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 5px;
  right: 0px;
  transition: all 0.3s ease;
  width: 200px;
  transform: translateX(${({ open }) => (open ? 0 : 180)}px);
  .toggle {
    width: 10%;
    button {
      padding: 2px 5px 2px 7px;
      border: none;
      background-color: ${({ theme }) => theme.colors.brandColor};
      color: ${({ theme }) => theme.colors.white};
      font-weight: 900;
      font-size: 0.85rem;
      border-bottom-left-radius: 35px;
      border-top-left-radius: 35px;
      height: 30px;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: ${({ theme }) => theme.colors.brandSolid};
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  .options {
    width: 90%;
    display: grid;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.3);
    button {
      padding: 4px 7px;
      border: none;
      background-color: ${({ theme }) => theme.colors.brandColor};
      color: ${({ theme }) => theme.colors.white};
      font-weight: 600;
      font-size: 0.85rem;
      &:hover {
        background-color: ${({ theme }) => theme.colors.brandSolid};
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
    .x1-nav {
      width: 100%;
      button {
        width: 50%;
        font-size: 0.75rem;
        padding: 5px 7px;
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
    .stage-nav {
      width: 100%;
      button {
        width: 45%;
        font-size: 0.75rem;
        padding: 5px 7px;
        &:focus {
          border: none;
          outline: none;
        }
      }
      .middle {
        width: 10%;
        height: 100%;
        padding: 5px 0;
      }
    }
  }
`;

export default TestingMenuWrapper;
