// Uncomment this line on creating a translation file
import { surveyLocalization } from 'survey-react';

export const spanishStrings = {
  pagePrevText: 'Atrás',
  pageNextText: 'Siguiente',
  completeText: 'Continuar', // Finalizar
  startSurveyText: 'Comenzar',
  otherItemText: 'Otro (describa)',
  noneItemText: 'Ninguno',
  selectAllItemText: 'Seleccionar Todos',
  progressText: 'Página {0} de {1}',
  emptySurvey: 'There is no visible page or question in the survey.',
  completingSurvey: 'Gracias por completar esta encuesta!',
  completingSurveyBefore: 'Nuestros registros muestran que ya completaste esta encuesta.',
  loadingSurvey: 'Encuesta esta cargando...',
  optionsCaption: 'Elija...',
  value: 'valor',
  requiredError: 'Por favor, responda la pregunta.',
  requiredErrorInPanel: 'Por favor, responda al menos una pregunta.',
  requiredInAllRowsError: 'Por favor, responda todos los casos presentados',
  numericError: 'El valor tiene que ser numérico.',
  textMinLength: 'Por favor, ingrese al menos {0} caracteres.',
  textMaxLength: 'Por favor, ingrese menos de {0} caracteres.',
  textMinMaxLength: 'Por favor, ingrese mas de {0} y menos de {1} caracteres.',
  minRowCountError: 'Por favor, complete al menos {0} filas.',
  minSelectError: 'Please select at least {0} variants.',
  maxSelectError: 'Please select no more than {0} variants.',
  numericMinMax: "El '{0}' debe ser mayor que {1} e igual o menor que {2}",
  numericMin: "El '{0}' debe ser igual o mayor que {1}",
  numericMax: "El '{0}' debe ser igual o menor que {1}",
  invalidEmail: 'Por favor, introduzca una dirección de correo válida.',
  invalidExpression: "The expression: {0} should return 'true'.",
  urlRequestError: "The request returned error '{0}'. {1}",
  urlGetChoicesError: "The request returned empty data or the 'path' property is incorrect",
  exceedMaxSize: 'El tamaño del archivo no debe exceder {0}.',
  otherRequiredError: 'Por favor, ingrese el otro valor.',
  uploadingFile:
    'Su archivo se esta cargando. Por favor, espere unos segundos e intente nuevamente.',
  loadingFile: 'Cargando...',
  chooseFile: 'Elegir archivo(s)...',
  confirmDelete: 'Desea eliminar este registro?',
  keyDuplicationError: 'Este valor debe ser único.',
  addColumn: 'Añadir columna',
  addRow: 'Añadir fila',
  removeRow: 'Eliminar',
  addPanel: 'Añadir nuevo',
  removePanel: 'Eliminar',
  choices_Item: 'item',
  matrix_column: 'Columna',
  matrix_row: 'Fila',
  savingData: 'Los datos se están registrando en nuestros servidores...',
  savingDataError:
    'Ha ocurrido un error y no pudimos registrar sus resultados en nuestros servidores.',
  savingDataSuccess: 'Los resultados se registraron de forma correcta!',
  saveAgainButton: 'Intentar nuevamente',
  timerMin: 'min',
  timerSec: 'seg.',
  timerSpentAll: 'Has utilizado {0} en esta pagina y {1} en total.',
  timerSpentPage: 'Has utilizado {0} en esta página.',
  timerSpentSurvey: 'Has utilizado {0} en total.',
  timerLimitAll: 'Has gastado {0} de {1} en esta pagina y {2} de {3} en total.',
  timerLimitPage: 'Has gastado {0} de {1} en esta página.',
  timerLimitSurvey: 'Han pasado {0} de {1} en total.',
  cleanCaption: 'Clean',
  clearCaption: 'Clear',
  removeFileCaption: 'Eliminar este archivo'
};

// Uncomment these two lines on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
surveyLocalization.locales.es = spanishStrings;
// surveyLocalization.localeNames['es'] = 'Español';
