const ratingValues = [
  {
    value: '0',
    text: '0'
  },
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  }
];


export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Instrucciones</h3>
                    <br>
                    A continuación se le presentará una serie de síntomas. Usted deberá indicar cuál de estos siente <strong>EN ESTE MOMENTO</strong>, seleccionando la opción correspondiente (0 = <em>Nada</em>; 1 = <em>Algo</em>; 2 = <em>Moderadamente</em>; 3 = <em>Severamente</em>).
                    <br><br><br>
                    Haga click en <em>Continuar</em>.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

const items = [
  { code: 'SSQ_01', statement: 'Malestar general' },
  { code: 'SSQ_02', statement: 'Fatiga' },
  { code: 'SSQ_03', statement: 'Dolor de cabeza' },
  { code: 'SSQ_04', statement: 'Tensión ocular' },
  { code: 'SSQ_05', statement: 'Dificultad para focalizar' },
  { code: 'SSQ_06', statement: 'Salivación aumentada' },
  { code: 'SSQ_07', statement: 'Sudor' },
  { code: 'SSQ_08', statement: 'Náuseas' },
  { code: 'SSQ_09', statement: 'Dificultad para concentrarse' },
  { code: 'SSQ_10', statement: '“Cabeza abombada”' },
  { code: 'SSQ_11', statement: 'Visión borrosa' },
  { code: 'SSQ_12', statement: 'Mareo estando con los ojos abiertos' },
  { code: 'SSQ_13', statement: 'Mareo estando con los ojos cerrados' },
  { code: 'SSQ_14', statement: 'Vértigo' },
  { code: 'SSQ_15', statement: 'Malestar estomacal' },
  { code: 'SSQ_16', statement: 'Flatulencia' }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_HEADER',
  html: `
  <legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
        0 = <em>Nada</em>; 1 = <em>Algo</em>; 2 = <em>Moderadamente</em>; 3 = <em>Severamente</em>.
        </span>
      </span>
    </div>
    <br>
  </div>
</legend>
  `
};

const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: items.map(item => {
    const { code, statement } = item;
    return {
      name: code,
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: code,
          title: statement,
          isRequired: true,
          minRateDescription: 'Nada',
          maxRateDescription: 'Severamente',
          rateValues: ratingValues
        }
      ]
    };
  })
};

export default baseInfo;
